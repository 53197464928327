<template>
  <section class="main transparent">
    <div class="stackable row">
      <div class="four wide column no-print">
        <section class="bordered padded">
          <h4>
            <translate translate-context="*/*/Title/Name">
              Contents
            </translate>
          </h4>
          <toc v-if="renderToc" :content-element="$refs.checklist" :level="2" />
        </section>
      </div>
      <div class="eight wide column">
        <section class="heavily bordered padded" ref="checklist">
          <h1>
            <translate translate-context="*/*/*">Setup checklist</translate>
          </h1>

          <h2>
            <translate translate-context="*/*/*">Requirements checklist</translate>
          </h2>
          <ul>
            <li><translate translate-context="Setup/*/*Paragraph">Softphone, or any similar telephony solution running on a workstation.</translate></li>
            <li><translate translate-context="Setup/*/*Paragraph">Windows or Ubuntu.</translate></li>
            <li><translate translate-context="Setup/*/*Paragraph">64bits Operating System.</translate></li>
            <li><translate translate-context="Setup/*/*Paragraph">100MB installation space.</translate></li>
            <li><translate translate-context="Setup/*/*Paragraph">Access to 16000Hz audio devices.</translate></li>
            <li><translate translate-context="Setup/*/*Paragraph">Internet connection (for telemetry, <strong>not</strong> for audio) with 500kB per hour available.</translate></li>
          </ul>

          <h2>
            <translate translate-context="*/*/Title/Name">
              Network
            </translate>
          </h2>
          <p><translate translate-context="Setup/*/*Paragraph">Authorize Vmocall TSM executable:</translate></p>
          <ul>
            <li><translate translate-context="Setup/*/*Paragraph">to use <strong>ANY protocol</strong> using <strong>TCP and UDP</strong>.</translate></li>
            <li><translate translate-context="Setup/*/*Paragraph">to reach port <strong>443</strong>.</translate></li>
            <li v-translate translate-context="Setup/*/*Paragraph">to reach any subdomain of the wildcard: <strong><code>*.altacalltsm.fr</code></strong></li>
          </ul>

          <h2>
            <translate translate-context="*/*/Title/Name">
              Audio
            </translate>
          </h2>
          <p><translate translate-context="Setup/*/*Paragraph">Authorize Vmocall TSM executable:</translate></p>
          <ul>
            <li><translate translate-context="Setup/*/*Paragraph">in your antivirus software, for both audio and network. For example, depending on your antivirus, add it in the Trusted Applications.</translate></li>
            <li><translate translate-context="Setup/*/*Paragraph">to use the microphone and speakers in the Windows Settings.</translate></li>
          </ul>

          Audio quality
          <ul>
            <li><translate translate-context="Setup/*/*Paragraph">If you are using a Virtual Machine, be sure to increase the buffersize of VoiceMeeter to 8192, and restart the computer.</translate></li>
          </ul>

          <h2>
            <translate translate-context="*/*/Title/Name">
              Miscellaneous
            </translate>
          </h2>
          <ul>
            <li><translate translate-context="Setup/*/*Paragraph">If you plan to run an A/B Test, lock Vmocall TSM's GUI automatically with <code>--start_locked</code> as command line parameter.</translate></li>
          </ul>

        </section>
      </div>
    </div>
  </section>
</template>
<script>
import Toc from '@/components/TOC'
export default {
  components: {
    Toc,
  },
  data () {
    return {
      renderToc: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderToc = true
    })
  }
}
</script>
